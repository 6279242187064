<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <b>B</b>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <b>I</b>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <b>U</b>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >H1</button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >H2</button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >H3</button>

        <button class="menubar__button" @click="commands.horizontal_rule">line</button>

        <button class="menubar__button" @click="commands.undo">Undo</button>

        <button class="menubar__button" @click="commands.redo">Redo</button>
      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import Icon from "./Components/Icon";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from "tiptap-extensions";
export default {
  components: {
    Editor,
    EditorContent,
    EditorMenuBar,
    Icon
  },
  props: ["value"],
  data() {
    return {
      editor: null
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History()
      ],
      onUpdate: ({ getHTML }) => {
        this.$emit("input", getHTML());
      }
    });

    this.editor.setContent(this.value);
  },
  watch: {
    value(val) {
      // so cursor doesn't jump to start on typing
      if (val !== this.editor.getHTML()) {
        this.editor.setContent(val);
      }
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>


<style>
.menubar__button {
  height: 20px;
  padding: 5px 5px !important;
  width: 40px !important;
  background-color: white !important;
  border-radius: 7px;
  cursor: pointer;
  font-size: 11px;
  margin-right: 5px;
  padding: 20px !important;
}
.ProseMirror {
  margin: 15px !important;
}
</style>
